import React from 'react';

import '../styles/star-rating.scss';

import { Star } from '@mui/icons-material';

function StarRatingComponent({ rating, size }: any) {
    const c = Math.round(rating);
    const l = 5 - c;

    return (
        <div>
            {
                [...Array(c)].map((star) => {
                    return (
                        <span className='star'>
                            <Star sx={{ width: size === 'small' ? '16px' : '24px' }} />
                        </span >
                    );
                })
            }
            {
                [...Array(l)].map((star) => {
                    return (
                        <span className='star-grayed'>
                            <Star sx={{ width: size === 'small' ? '16px' : '24px' }} />
                        </span>
                    );
                })
            }
        </div >
    );
}

export default StarRatingComponent;